.arrow-nav {
  position: relative;
  text-decoration: none;
  font-weight: 900;
  transition: transform 0.15s ease-in;
  display: inline-block;
  position: relative;
}

.arrow-nav-primary,
.arrow-nav-primary:hover {
  color: var(--bb-color-primary);
}

.arrow-nav:not(.arrow-nav-no-action):hover {
  transform: scale(1.05);
}

.arrow-nav::after {
  content: "";
  width: 65px;
  height: 24px;
  position: absolute;
}

/* bottom right side arrow - forward navigation */
.arrow-nav.bottom-end {
  margin-right: 30px;
  margin-bottom: 1rem;
}

.arrow-nav.bottom-end::after {
  right: -30px;
  bottom: -60%;
  background-image: url("/assets/img/arrowWhite.svg");
}

.arrow-nav.bottom-end.arrow-nav-primary::after {
  background-image: url("/assets/img/arrowBlue.svg");
}

/* top left side arrow - backward navigation */
.arrow-nav.top-start {
  margin-left: 30px;
  margin-top: 1rem;
}

.arrow-nav.top-start::after {
  left: -30px;
  top: -60%;
  background-image: url("/assets/img/arrowWhiteLeft.svg");
}

.arrow-nav.top-start.arrow-nav-primary::after {
  background-image: url("/assets/img/arrowBlueLeft.svg");
}
