::-webkit-scrollbar {
  height: 100%;
  width: 8px;
  background: var(--bb-color-accent);
  padding-right: 5px;
  margin-right: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--bb-color-primary);
  margin-right: 5px;
}

::-webkit-scrollbar-corner {
  background: #000;
}
