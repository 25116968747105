@font-face {
  font-family: "Aileron";
  src: url(./aileron/Aileron-Regular.otf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Aileron";
  src: url(./aileron/Aileron-Italic.otf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Aileron";
  src: url(./aileron/Aileron-SemiBold.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Aileron";
  src: url(./aileron/Aileron-Bold.otf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Aileron";
  src: url(./aileron/Aileron-Heavy.otf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

.fw-bolder {
  font-weight: 900;
}

.fs-small {
  font-size: 0.875em;
}
