.shimmer-container {
  position: relative;
  overflow: hidden;
}

.shimmer-line,
.shimmer-img {
  width: 100%;
  background: var(--bb-color-primary-lighter);
}

.shimmer-line {
  height: 20px;
  margin: 20px 0;
}

.shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;

  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );

  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(200%);
  }
}
