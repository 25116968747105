.text-primary {
  color: var(--bb-color-primary) !important;
}

.text-primary-hover:hover {
  color: var(--bb-color-primary) !important;
}

.text-primary-lighter-hover:hover {
  color: var(--bb-color-primary-lighter) !important;
}

.text-accent {
  color: var(--bb-color-accent) !important;
}

.text-white-hover:hover {
  color: var(--bs-white) !important;
}

.text-decoration-underline-hover:hover {
  text-decoration: underline !important;
}

.fs-5-5 {
  font-size: 1.125rem !important;
}

.text-size-md {
  font-size: 18px;
}

.text-size-lg {
  font-size: 20px;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-nowrap {
  white-space: nowrap;
}

.text-size-responsive {
  font-size: clamp(12px, 4.5vw, 20px);
}

.fw-bold-hover:hover {
  font-weight: 700 !important;
}
