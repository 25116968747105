.nav {
  --bs-nav-link-color: var(--bb-color-primary);
  --bs-nav-link-hover-color: var(--bb-color-primary);
}

.nav-pills {
  --bs-nav-pills-link-active-color: var(--bb-color-primary);
  --bs-nav-pills-link-active-bg: transparent;
  --bs-nav-pills-border-radius: 0;
}

.nav-pills .nav-link.active {
  font-weight: bold;
}
