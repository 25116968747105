@import url("./fonts/fonts.css");

:root {
  --bb-color-primary: #273c89;
  --bb-color-primary-light: #273c8999;
  --bb-color-primary-lighter: #acaccb;
  --bb-color-primary-lighter-2: #7785b7;
  --bb-color-accent: #ecc36c;
  --bb-color-accent-light: #f4eddd;
  --bb-color-light: #f3f6ff;
  --bb-content-max-width: 1140px;
  --bb-body-font-family: "Aileron";
  --bs-body-font-family: var(--bb-body-font-family);
  --hover-transition: 0.15s ease-in;
}

@import url("./typography.css");
@import url("./background.css");
@import url("./spacing.css");
@import url("./scrollar.css");
@import url("./button.css");
@import url("./transform.css");
@import url("./shadow.css");
@import url("./nav.css");
@import url("./container.css");
@import url("./image.css");
@import url("./border.css");
@import url("./form.css");
@import url("./tab.css");
@import url("./accordian.css");
@import url("./shimmer.css");
