.border-primary {
  border-color: var(--bb-color-primary) !important;
}

.border-accent {
  border-color: var(--bb-color-accent) !important;
}

.border-20 {
  border-width: 20px !important;
}

.border-radius-0 {
  border-radius: 0;
}
