.blueberry-theme-form input,
.blueberry-theme-form textarea,
.blueberry-theme-form select {
  border-radius: 0;
  border-color: var(--bb-color-primary);
}

.material-theme {
  border-radius: 0;
  border-bottom: solid 1px var(--bb-color-primary) !important;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
}

.material-theme:focus {
  box-shadow: none;
}

.blueberry-theme-form input[type="checkbox"].size-large {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.075em;
  margin-right: 0.5em;
}
