.transition {
  transition: all var(--hover-transition);
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.scale-medium-hover:hover {
  transform: scale(1.1);
}
