.bg-primary {
  background-color: var(--bb-color-primary) !important;
}

.bg-primary-light {
  background-color: var(--bb-color-primary-light) !important;
}

.bg-primary-lighter-2 {
  background-color: var(--bb-color-primary-lighter-2) !important;
}

.bg-accent {
  background-color: var(--bb-color-accent) !important;
}

.bg-accent-light {
  background-color: var(--bb-color-accent-light) !important;
}

.bg-white-hover:hover {
  background-color: var(--bs-white) !important;
}

.bg-primary-illustration {
  background: url("/assets/img/backgroundBlog2.png");
}

.bg-primary-illustration-2 {
  background: url("/assets/img/backgroundHome.png");
}

.bg-theme-light {
  background-color: var(--bb-color-light);
}

.leaf-icon-after::after {
  content: "";
  margin-left: 0.5em;
  margin-right: -3em;
  display: inline-block;
  height: 0.8em;
  width: 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAgCAYAAAC7FpAiAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANoSURBVHgB3ZhNTttAFIDfG0+6a5uKQMWq5gZh3RKSG9ATEHaopSI5QZITAFJSdRc4AfQEiWDRZcMJYIdKi5r+bRrb0/fGceL84Dh/JuonRbHH4+f3PO9vjDAFN592zVgrllZKmWjgC1AqTsNxfRHxWgE0wVY/3HPVRBDNsLIVOHFQ6Moy8Cl6culZrjj6R2wubZTXJYzJ14u9tADYJGFZZSEJUiRLC+/Tgsb5X3gDehKEBXk+dmX1o+ihjnJ23LljKE+TC3SYhgWAVuJ6KVVZ4+ORK3FVy8UfS6tKh1uwQNigSt5x4Eq0XedUef64IPhXgRH3Tby72NsnC2uLZgDjIO74z4caQQYUSPlDWEQQj5c3ynX/0IARX873tsiAIiworVar1D/WY8RNbdeUCFWYE5SNQ9eJYdDLLa1mPlz3j/dkp5iUB7OKAYqnBuXyhnKcS120uGAphzIcTiSfgzmRqhSHXesYcXv+Lku2TpNG6w7AJSl/9tuSDR54Iq19RNxWCEkuWApDl6UB/Cm1n447GcIpwPjUqeXI/7Lks8RGOfPHkkVyGa4rp/T7zrFFvyRMCwXzSur98X2X9UpwPaAXZUIYeeQm9MY/0ts+XMscah/ngsgZDcDKzTolsxv9taxS0BxtBKLaprWGEdQ5sBK+9NZtRax0+K5oPNiNhgWzH9cI6ocClNDKLw9Vfu591FGQG3ngbe1NUkjxeeCC6zZ5v/LtuQcQQRPY31oEIYUhzN6boekIVUq8qnQqNvv8E8MuKFQ5iAAdB7adCTtfUkAnvczn3bya6vog91Dk88UoeygLMD8qDvxIerum3oAodfLTjuXWMhWdcbh6U/Grqoj3Dxx/z1Pls3HukQJpW0kBRNWw4yrcP3H7EXUH204gRRgTSXeSAV3L2x1scV4pM4CjSQxgeorD3fnbKrUGWYgacmXyhCxMSKfteDADXFfOwhRoI7QLPYAB7Q5g6rSN7Sx0BRHCtcg2nPzKy9HVOAwyFjMKEGEUcy2ybfWa2okGzAhBjZ8J0XFEtWh9JTM7AxjZsqydR4ZRo5gwYX4MNJGzpJNieWdngFOYsTFzVd5jYBPxjaq1Q9tUquTbMAE6aBFOeJs6b+U7zwy6yPsGcCCNAjb5q3T/VtP9eqGaSoi6/iBAHwaiUvy/4x8FYXsh2YfZCAAAAABJRU5ErkJggg==");
  background-size: contain;
  background-repeat: no-repeat;
}
