.btn {
  --bs-btn-border-radius: 0;
}

.btn-light {
  --bs-btn-bg: var(--bb-color-accent-light);
}

.btn-primary {
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bb-color-primary);
  --bs-btn-border-color: var(--bb-color-primary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bb-color-primary);
  --bs-btn-hover-border-color: var(--bb-color-primary);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bb-color-primary);
  --bs-btn-active-border-color: var(--bb-color-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-white);
  --bs-btn-disabled-bg: var(--bb-color-primary);
  --bs-btn-disabled-border-color: var(--bb-color-primary);
}
